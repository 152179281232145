<template>
  <div>
    <div v-if="isLoading" class="my-14">
      <v-row justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </div>
    <div v-else>
      <div class="text-center font-weight-bold" v-if="!getCarbonEmissions">
        Sorry we lost your calculation, Please retry !
        <div>
          <v-btn
            depressed
            class="my-4"
            color="#00b08d"
            dark
            rounded
            @click="
              $router.push({
                name: 'Calculator',
              })
            "
            >back to Calculator</v-btn
          >
        </div>
      </div>
      <div class="chose-plan-page" v-if="getCarbonEmissions">
        <div class="text-header">
          You generate {{ getCarbonEmissions.toFixed(2) }} tCO₂ per year
        </div>
        <div
          class="text-description my-3"
          v-if="getCarbonEmissions <= getAnnualAverageEmission"
        >
          That’s
          {{ getPercentageAway(getCarbonEmissions, getAnnualAverageEmission) }}%
          less than the United Kingdom average, you ledge 🌟
        </div>
        <div
          class="text-description my-3"
          v-if="getCarbonEmissions > getAnnualAverageEmission"
        >
          That’s
          {{ getPercentageAway(getAnnualAverageEmission, getCarbonEmissions) }}%
          more than the United Kingdom average
        </div>
        <div class="text-description my-3">
          Your offset subscription supports projects across the world.
          <a target="”_blank”" href="https://www.zero.thetoucan.app/projects"
            >Find out more</a
          >
        </div>
        <div v-for="product in products" :key="product.id">
          <PlanCard
            :userAlreadySubscribed="!!subscription"
            :product="product"
          />
        </div>

        <div
          class="text-decoration-underline text-description mt-8 pointer"
          @click="startOver"
        >
          Start over
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanCard from "@/components/choose-plan/PlanCard.vue";
import CalculatorMixin from "@/mixins/CalculatorMixin.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ApiService } from "@/services/ApiService";

export default {
  async created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Your plan");

    //? check beforeEnter conditions first
    await this.beforeEnterMiddleware();
    await this.getSubscription();
    await this.fetchProducts();
  },

  components: {
    PlanCard,
  },
  data() {
    return {
      isLoading: false,
      subscription: null,
      firstName: null,
      products: [],
    };
  },
  mixins: [CalculatorMixin],
  computed: {
    ...mapGetters([
      "getCarbonEmissions",
      "getAnnualAverageEmission",
      "getUserFSDocument",
    ]),
  },
  methods: {
    ...mapMutations([
      "resetUserInputs",
      "setErrorSnackbarMessage",
      "setCurrentAppBarTitle",
    ]),

    ...mapActions(["fetchUserFSDocument"]),
    async fetchProducts() {
      try {
        this.isLoading = true;

        const response = await ApiService.get("/products");
        this.products = response.data;
        this.products = this.products.sort(
          (a, b) => parseInt(a.percentage) - parseInt(b.percentage)
        );
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    beforeEnterMiddleware() {
      //? if the user didn't calculate his emissions, redirect him to the calculator
      if (!this.getCarbonEmissions) {
        this.$router.push({ name: "Calculator" });
      }
    },
    async getSubscription() {
      try {
        this.isLoading = true;
        await this.fetchUserFSDocument();
        this.subscription = this.getUserFSDocument?.subscription;
        this.firstName = this.getUserFSDocument?.firstName;
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    startOver() {
      this.activeCalculatorStepNumber = 0;
      this.resetUserInputs();
      this.$router.push({
        name: "Calculator",
      });
    },
    // todo: move to utils file
    getPercentageAway(x, y) {
      const percentageDiff = (x * 100) / y;
      return (100 - percentageDiff).toFixed(2);
    },
  },
};
</script>

<style  scoped>
.chose-plan-page {
  font-family: "League Spartan", sans-serif;
}
</style>